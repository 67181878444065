import React from 'react'
import TopNavBar from 'components/TopNavBar'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import api from 'api'
import * as yup from 'yup'
import ReactTable from 'react-table'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import Fab from '@material-ui/core/Fab'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import swal from 'sweetalert'
import ChipInput from 'material-ui-chip-input'

const fields = yup.object().shape({
  id: yup.string().required(),
  line: yup
    .string()
    .required()
    .meta(),
  title: yup
    .string()
    .trim()
    .required(),
  description: yup.string(),
  imgs: yup
    .array()
    .of(yup.string())
    .default(() => []),
  bundledItems: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        qty: yup.number().default(1)
      })
    )
    .default(() => []),
  on_hand_qty: yup.number().default(0),
  reserved_qty: yup.number().default(0),
  active: yup.bool(),
  price: yup.number().required(),
  is_taxable: yup.bool(),
  ship_type: yup.string().oneOf(['flat', 'box']),
  ship_weight: yup
    .number()
    .required()
    .meta('in ounces'),
  defaults: yup.object().meta(`default variant (if applicable) i.e. {size: 'medium', color: 'red'}`),
  variants: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required()
      })
    )
    .default(() => []),
  is_variant: yup
    .boolean()
    .required()
    .default(false)
})

const defaultFields = (() => {
  return Object.keys(fields).reduce((prev, curr) => {
    prev[curr] = null
    return prev
  }, {})
})()

class MtoOptions extends React.Component {
  transformIn = mto => Object.entries(mto).map(([k, v]) => ({ k, v }))
  transformOut = arr => arr.reduce((a, b) => ({ ...a, [b.k]: b.v }), {})

  state = {
    options: []
  }

  componentDidUpdate(oldProps) {
    if (JSON.stringify(this.props.mto) !== JSON.stringify(oldProps.mto)) {
      this.setState({ options: this.transformIn(this.props.mto) })
    }
  }
  handleKeyChange = (ix, value) => {
    const options = this.state.options.map(x => x)
    options[ix].k = value
    this.props.onChange(this.transformOut(options))
  }
  handleAddChip = (optIx, chip) => {
    const options = this.state.options.map(x => x)
    options[optIx].v.push(chip)
    this.props.onChange(this.transformOut(options))
  }
  handleDeleteChip = (optIx, chip) => {
    const options = this.state.options.map(x => x)
    options[optIx].v = options[optIx].v.filter(x => x !== chip)
    this.props.onChange(this.transformOut(options))
  }
  handleDeleteOption = optIx => {
    const options = this.state.options.filter((o, ix) => ix !== optIx)
    this.props.onChange(this.transformOut(options))
  }
  render() {
    return this.state.options.map((option, ix) => (
      <React.Fragment key={ix}>
        <Grid item xs={1}>
          <Fab size="small" color="secondary" onClick={() => this.handleDeleteOption(ix)}>
            X
          </Fab>
        </Grid>
        <Grid item xs={4}>
          <TextField fullWidth value={option.k} onChange={e => this.handleKeyChange(ix, e.target.value)} />
        </Grid>
        <Grid item xs={7}>
          <ChipInput
            fullWidth
            fullWidthInput
            value={option.v}
            onAdd={v => this.handleAddChip(ix, v)}
            onDelete={v => this.handleDeleteChip(ix, v)}
          />
        </Grid>
      </React.Fragment>
    ))
  }
}

class InventoryItem extends React.Component {
  static propTypes = { match: PropTypes.any.isRequired }
  state = {
    id: '',
    line: '',
    title: '',
    description: '',
    imgs: [],
    categories: [],
    on_hand_qty: 0,
    reserved_qty: 0,
    active: false,
    price: '',
    is_taxable: false,
    ship_type: 'flat',
    ship_weight: 0,
    defaults: {},
    variants: [],
    is_variant: false,
    errors: {},
    has_variants: false,
    mto_options: {},
    bundledItems: []
  }

  async componentDidMount() {
    console.log('default fields', defaultFields)
    console.log(this.props.match.params)
    if (this.props.match.params.id && this.props.match.params.id !== 'new') {
      const response = await api.admin.inventory.getItem(this.props.match.params.id)
      this.setState({ ...response.data })
    } else {
    }
  }

  renderField(label, field, sizes, props = {}) {
    const { errors } = this.state

    return (
      <Grid item {...sizes}>
        <TextField
          id={field}
          label={label}
          value={this.state[field]}
          error={!!errors[field]}
          helperText={errors[field]}
          className="fill"
          fullWidth
          onChange={event => this.setState({ [field]: event.target.value })}
          {...props}
        />
      </Grid>
    )
  }

  renderChip(label, field, sizes, props = {}) {
    return (
      <Grid item {...sizes}>
        <ChipInput
          label={label}
          fullWidth
          fullWidthInput
          value={this.state[field]}
          onAdd={v => this.setState({ [field]: this.state[field].concat(v) })}
          onDelete={v => this.setState({ [field]: this.state[field].filter(s => s !== v) })}
          {...props}
        />
      </Grid>
    )
  }

  renderSelect(label, field, sizes, props, values) {
    return (
      <Grid item {...sizes}>
        <TextField
          id={field}
          label={label}
          select
          value={this.state[field]}
          error={!!this.state.errors[field]}
          helperText={this.state.errors[field]}
          className="fill"
          fullWidth
          onChange={event => this.setState({ [field]: event.target.value })}
          {...props}
        >
          {values.map(v => (
            <MenuItem key={v} value={v} style={{ textAlign: 'left' }}>
              {v}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }

  renderCheckBox(label, field, sizes, props = {}) {
    return (
      <Grid item {...sizes}>
        <FormControlLabel
          className="full-width"
          align="left"
          key={field}
          control={
            <Checkbox
              color="primary"
              name={field}
              checked={this.state[field]}
              onChange={event => this.setState({ [field]: event.target.checked })}
            />
          }
          label={label}
          {...props}
        />
      </Grid>
    )
  }

  rowEdit = cellInfo => {
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const variants = [...this.state.variants]
          variants[cellInfo.index][cellInfo.column.id] = e.target.innerHTML
          this.setState({ variants })
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.variants[cellInfo.index][cellInfo.column.id]
        }}
      />
    )
  }

  rowEditBundle = cellInfo => {
    const rowExists = this.state.bundledItems[cellInfo.index] !== undefined
    return (
      <div
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const bundledItems = [...this.state.bundledItems]
          bundledItems[cellInfo.index][cellInfo.column.id] = e.target.innerHTML
          this.setState({ bundledItems })
        }}
        dangerouslySetInnerHTML={{
          __html: rowExists ? this.state.bundledItems[cellInfo.index][cellInfo.column.id] : ''
        }}
      />
    )
  }

  render() {
    return (
      <div>
        <TopNavBar value="/inventory" />
        <main style={{ backgroundColor: '#eee' }}>
          <div className="container" style={{ position: 'relative' }}>
            <Typography variant="h5" align="left">
              Editing <em>{this.state.title}</em>
            </Typography>
          </div>
          <Paper className="container">
            <Grid container spacing={2}>
              {this.renderField('ID', 'id', { xs: 12 })}
              {this.renderField('Title', 'title', { xs: 6 })}
              {this.renderField('Product Line', 'line', { xs: 6 })}
              {this.renderField(
                'Description',
                'description',
                { xs: 12 },
                { multiline: true, rows: 4, rowsMax: 999, variant: 'outlined' }
              )}
              {this.renderChip('Categories', 'categories', { xs: 12 })}
              {this.renderChip('Images', 'imgs', { xs: 12 })}
              {this.renderField(
                'Retail Price',
                'price',
                { xs: 4 },
                { InputProps: { startAdornment: <InputAdornment position="start">$</InputAdornment> } }
              )}
              {this.renderSelect('Shipping Type', 'ship_type', { xs: 4 }, {}, ['flat', 'box'])}
              {this.renderField('Shipping Weight (oz)', 'ship_weight', { xs: 4 }, {})}
              {this.renderField('On Hand Quantity', 'on_hand_qty', { xs: 12 }, {})}
              {this.renderCheckBox('Is this product taxable?', 'is_taxable', { xs: 6 }, { style: { height: '1rem' } })}
              {this.renderCheckBox('Is this product active?', 'active', { xs: 12 }, { style: { height: '1rem' } })}
              {this.renderCheckBox(
                'Is this a variant of a parent product?',
                'is_variant',
                { xs: 12 },
                { style: { height: '1rem' } }
              )}
            </Grid>
          </Paper>
          {!this.state.is_variant && (
            <Paper className="container mt-2x5">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    <Typography variant="h6" align="left">
                      Variants (optional)
                    </Typography>
                    <Fab
                      color="secondary"
                      style={{ position: 'absolute', right: 0, top: '-2.5rem' }}
                      onClick={() => this.setState({ variants: [...this.state.variants, {}] })}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                  <ReactTable
                    data={this.state.variants}
                    className="-striped -highlight bg-white"
                    minRows={this.state.variants.length}
                    resizable
                    showPagination={false}
                    editable
                    columns={[
                      {
                        Header: 'Size',
                        id: 'size',
                        className: 'left',
                        accessor: d => d.size,
                        Cell: this.rowEdit
                      },
                      {
                        Header: 'Color',
                        id: 'color',
                        className: 'center',
                        accessor: d => d.color,
                        Cell: this.rowEdit
                      },
                      {
                        Header: 'ID',
                        id: 'id',
                        className: 'center',
                        accessor: d => d.id,
                        Cell: this.rowEdit
                      },
                      {
                        Header: 'Default',
                        id: 'default',
                        className: 'center',
                        accessor: d => d.isDefault,
                        Cell: cellInfo => (
                          <Checkbox
                            color="primary"
                            style={{ height: '1rem' }}
                            checked={this.state.variants[cellInfo.index][cellInfo.column.id] || false}
                            onChange={e => {
                              const variants = [...this.state.variants.map(x => ({ ...x, default: false }))]
                              variants[cellInfo.index][cellInfo.column.id] = !variants[cellInfo.index][cellInfo.column.id]
                              this.setState({ variants })
                            }}
                          />
                        )
                      }
                    ]}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
          <Paper className="container mt-2x5">
            <div>
              <Typography variant="h6" align="left">
                Make To Order Options (optional)
              </Typography>
              <Fab
                color="secondary"
                style={{ position: 'absolute', right: 0, top: '-2.5rem' }}
                onClick={() =>
                  this.setState({
                    mto_options: { ...this.state.mto_options, [`new${Object.keys(this.state.mto_options).length}`]: [] }
                  })
                }
              >
                <AddIcon />
              </Fab>
            </div>
            <Grid container spacing={2}>
              <MtoOptions mto={this.state.mto_options} onChange={mto_options => this.setState({ mto_options })} />
            </Grid>
            {JSON.stringify(this.state.mto_options)}
          </Paper>

          <Paper className="container mt-2x5">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <Typography variant="h6" align="left">
                    Bundled Items (optional)
                  </Typography>
                  <Fab
                    color="secondary"
                    style={{ position: 'absolute', right: 0, top: '-2.5rem' }}
                    onClick={() => this.setState({ bundledItems: [...this.state.bundledItems, { id: '', qty: '' }] })}
                  >
                    <AddIcon />
                  </Fab>
                </div>
                <ReactTable
                  data={this.state.bundledItems}
                  className="-striped -highlight bg-white"
                  minRows={this.state.bundledItems.length}
                  rowsMax={this.state.bundledItems.length}
                  resizable
                  showPagination={false}
                  editable
                  columns={[
                    {
                      Header: 'ID',
                      id: 'id',
                      className: 'left',
                      accessor: d => d.id,
                      Cell: this.rowEditBundle
                    },
                    {
                      Header: 'Quantity',
                      id: 'qty',
                      className: 'center',
                      accessor: d => d.qty,
                      Cell: this.rowEditBundle
                    },
                    {
                      Header: 'Delete',
                      id: 'delete',
                      accessor: d => 'delete',
                      Cell: cellInfo => (
                        <Fab
                          size="small"
                          onClick={() =>
                            this.setState({ bundledItems: this.state.bundledItems.filter((o, ix) => ix !== cellInfo.index) })
                          }
                        >
                          X
                        </Fab>
                      )
                    }
                  ]}
                />
              </Grid>
            </Grid>
          </Paper>

          <div className="container right mt-2x5">
            <div className="flex-wrapper">
              <div className="flex-0">
                <Button variant="contained" onClick={() => this.props.history.push('/inventory')}>
                  &lt; Back
                </Button>
              </div>
              <div className="flex-1"></div>
              <div className="flex-0">
                <Button color="primary" variant="contained" onClick={this.save}>
                  Save
                </Button>
                &nbsp;
                <Button color="secondary" variant="contained" onClick={this.delete}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }

  // async validate(state, field) {
  //   const errors = {}
  //   const detail = { ...state.detail }
  //   if (field) {
  //     console.log('validating...', field)
  //     await this.validations[field].validate(detail[field]).catch(err => (errors[field] = err.message))
  //   } else {
  //     for (const field in this.validations) {
  //       await this.validations[field].validate(detail[field]).catch(err => (errors[field] = err.message))
  //     }
  //   }
  //   // set state and return validation results
  //   this.setState({ errors })
  //   return Object.keys(errors).length === 0
  // }

  save = async () => {
    const isValid = await fields.isValid(this.state)
    const item = {
      ...this.state,
      errors: undefined,
      variants: this.state.variants.length > 0 ? this.state.variants : undefined,
      bundledItems:
        this.state.bundledItems.length > 0 ? this.state.bundledItems.map(x => ({ ...x, qty: +x.qty })) : undefined
    }
    if (isValid) {
      console.log('saving')
      await api.admin.inventory.saveItem(this.state.id, item)
      await swal('Item Was Saved!', { icon: 'success' })
      this.props.history.goBack()
    } else {
      console.log('not valid')
      await swal('Not Valid')
    }
  }
  delete = () => {
    swal({
      title: 'Confirmation',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        await api.admin.inventory.deleteItem(this.props.match.params.id)
        swal('Item was deleted', { icon: 'success' })
        this.props.history.goBack()
      }
    })
  }
}

export default withRouter(InventoryItem)
