import React, { Component } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import './App.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import Pages from './pages'
import CircularProgress from '@material-ui/core/CircularProgress'
import auth from './helpers/Auth'
// import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'

function SecuredRoute(props) {
  const { component: Component, path, checkingSession } = props
  return (
    <Route
      path={path}
      render={() => {
        if (checkingSession) {
          return <CircularProgress style={{ margin: '5rem auto' }} />
        } else if (!auth.isAuthenticated()) {
          console.log('not authenticated')
          return <Redirect to="/" />
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}
SecuredRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  checkingSession: PropTypes.bool
}

function UnsecuredRoute(props) {
  const { component: Component, path, checkingSession } = props
  return (
    <Route
      path={path}
      render={() => {
        if (checkingSession) {
          return <CircularProgress style={{ margin: '5rem auto' }} />
        } else if (auth.isAuthenticated()) {
          console.log('not authenticated')
          return <Redirect to="/inventory" /> // Todo: long term there should be a better solution. Home worked well. or we could set a default-route
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}
UnsecuredRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  checkingSession: PropTypes.bool
}

class App extends Component {
  static propTypes = {
    location: PropTypes.any
  }
  state = {
    checkingSession: true,
    auth
  }

  async componentDidMount() {
    if (this.props.location.pathname === '/callback') {
      this.setState({ checkingSession: false })
      return
    }
    try {
      await auth.silentAuth()
      this.forceUpdate()
    } catch (err) {
      if (err.error !== 'login_required') {
        // Sentry.captureException(err)
      }
    }
    this.setState({ checkingSession: false })
  }

  render() {
    const { checkingSession } = this.state
    return (
      <div className="App">
        <CssBaseline />
        <Switch>
          <UnsecuredRoute exact path="/" component={Pages.Login} checkingSession={checkingSession} />
          <SecuredRoute exact path="/inventory" component={Pages.Inventory.List} checkingSession={checkingSession} />
          <SecuredRoute path="/inventory/:id" component={Pages.Inventory.Item} checkingSession={checkingSession} />
          <SecuredRoute exact path="/orders" component={Pages.Orders.List} checkingSession={checkingSession} />
          <SecuredRoute path="/orders/:id" component={Pages.Orders.Item} checkingSession={checkingSession} />
          <Route path="/callback" component={Pages.Callback} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(App)
