import React from 'react'
import TopNavBar from 'components/TopNavBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link, withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import api from '../../api'
import Typography from '@material-ui/core/Typography'

class OrderList extends React.Component {
  state = {
    data: {},
    loading: true
  }
  async componentDidMount() {
    const response = await api.admin.orders.getActiveOrders()
    console.log(response.data)
    this.setState({ data: response.data, loading: false })
  }
  render() {
    if (this.state.loading) {
      return (
        <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', paddingTop: '7rem' }}>
          <TopNavBar value="/orders" />
          <CircularProgress />
        </div>
      )
    }
    const { data } = this.state
    return (
      <div>
        <TopNavBar value="/orders" />
        <main style={{ backgroundColor: '#eee' }}>
          <Typography variant="h3">Orders</Typography>
          <div className="container">
            <ReactTable
              data={data}
              defaultPageSize={15}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
              className="-striped -highlight bg-white"
              resizable
              columns={[
                {
                  Header: 'ID',
                  id: 'id',
                  className: 'center',
                  accessor: d => (
                    <Link to={`/orders/${d.id}`} className={'tableButton'}>
                      {d.order_number}
                    </Link>
                  )
                },
                {
                  Header: 'Name',
                  id: 'name',
                  className: 'center',
                  accessor: d => `${d.first_name} ${d.last_name}`
                },
                {
                  Header: 'items',
                  id: 'line',
                  className: 'center',
                  accessor: d => d.items.length
                },
                {
                  Header: 'Date',
                  id: 'date',
                  className: 'center',
                  accessor: d => new Date(d.order_date).toLocaleDateString()
                }
              ]}
            />
          </div>
        </main>
      </div>
    )
  }
}

export default withRouter(OrderList)
