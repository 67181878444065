import axios from 'axios'
import swal from 'sweetalert'
import Auth from 'helpers/Auth'
// import * as Sentry from '@sentry/browser'
/**
 *
 * @param {uri, timeout, authRequired} options
 */
function xhrFactory(options = {}) {
  const headers = options.headers || {}

  const xhr = axios.create({
    baseURL: (options && options.uri) || process.env.REACT_APP_BASE_API_URI,
    timeout: (options && options.timeout) || 15000,
    headers
  })

  xhr.interceptors.request.use(async config => {
    if (!options.noHeaders) {
      await Auth.keepActive()
      if (Auth.isAuthenticated()) {
        config.headers.Authorization = `Bearer ${Auth.getIdToken()}`
      }
      // config.headers['cache-control'] = 'no-cache'
    }
    return config
  })

  xhr.interceptors.response.use(
    function(res) {
      console.log(res)
      return res
    },
    function(error) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            swal({
              title: 'Session Expired',
              text: 'Your session has expired. you are being redirected to the login page.',
              type: 'warning',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Ok',
              closeOnConfirm: false
            }).then(() => {
              Auth.logout()
            })
            return Promise.reject(error)
          case 403:
            swal({
              title: 'Session Expired',
              text: 'Sorry, you are unauthorized to perform that task.',
              type: 'warning',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Ok',
              closeOnConfirm: true
            })
            return Promise.reject(error)
          case 404:
          case 422:
          case 500:
          case 502:
          default:
            swal({
              title: 'Sorry, and unexpected error occurred. If problem persists, please contact support.',
              text: error.response.data.message,
              type: 'warning',
              confirmButtonColor: '#DD6B55',
              confirmButtonText: 'Ok'
            })
            // Sentry.captureException(error)
            return Promise.reject(error)
        }
      }
      return Promise.reject(error)
    }
  )

  return xhr
}

export default xhrFactory
