import React from 'react'
import TopNavBar from 'components/TopNavBar'
import { withStyles } from '@material-ui/core/styles'
import layout from 'styles/layout'
import Button from '@material-ui/core/Button'
import auth from 'helpers/Auth'
import PropTypes from 'prop-types'

class Login extends React.Component {
  static propTypes = {
    classes: PropTypes.any
  }
  render() {
    return (
      <div>
        <TopNavBar noTabs />
        <main>
          <div className={this.props.classes.contentWithHeader}>
            <div className="bg-mount tall">
              <div className="container">
                <h1>Gem 007 API</h1>
                <Button onClick={() => auth.login()}>Login</Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

export default withStyles(layout)(Login)
