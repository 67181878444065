// import auth0 from 'auth0-js'
// import * as Sentry from '@sentry/browser'

class authC {
  constructor(domain, params) {
    this.domain = domain
    this.params = params
    this.id_token = ''
    this.accessToken = ''
    this.id_token_obj = {}
    this.access_token_obj = {}
    // eslint-disable-next-line
    window.addEventListener('message', this.authenticate.bind(this))
    this.buildLogout = this.buildLogout.bind(this)
  }

  createParams(obj) {
    return Object.keys(obj)
      .map(key => `${key}=${obj[key]}`)
      .join('&')
  }

  readParams(hash) {
    let params = {}
    let vars = hash.substring(1).split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      params[pair[0]] = decodeURIComponent(pair[1])
    }
    return params
  }

  buildLogin() {
    const { response_type, client_id, redirect_uri, scope } = this.params
    const query = this.createParams({ response_type, client_id, redirect_uri, state: 'STATE', scope })
    return this.domain + '/login?' + query
  }

  buildLogout() {
    const { client_id, logout_uri } = this.params
    const query = this.createParams({ client_id, logout_uri })
    return this.domain + '/logout?' + query
  }

  buildRefresh() {
    const { response_type, client_id, redirect_uri, scope } = this.params
    const query = this.createParams({ prompt: 'none', state: 'STATE', response_type, client_id, redirect_uri, scope })
    return this.domain + '/oauth2/authorize?' + query
  }

  authorize() {
    window.location = this.buildLogin()
  }
  logout() {
    window.location = this.buildLogout()
  }

  authenticate(event) {
    if (event.origin === window.location.origin && event.data.idToken) {
      console.log('authenticating...', event)
      // if (typeof event.data === 'string' && event.data.indexOf('#') === 0) {
      //   let authParams = this.readParams(event.data)
      //   console.log('in auth', JSON.stringify(authParams))
      //   this.id_token = authParams.id_token
      //   this.access_token = authParams.access_token
      // }
      this.id_token = event.data.idToken
      this.access_token = event.data.accessToken
      console.log(this)
    }
  }

  checkSession(obj, cb) {
    console.log('checking session')
    const auth_el = document.createElement('iframe')
    auth_el.setAttribute('src', this.buildRefresh())
    auth_el.setAttribute('style', 'height:1px;width:1px;')

    document.body.appendChild(auth_el)
    setTimeout(() => {
      document.body.removeChild(auth_el)
      console.log('checking session 2', auth_el)
      if (this.id_token) {
        cb(null, {
          idToken: this.id_token,
          accessToken: this.access_token,
          idTokenPayload: this.parseJwt(this.id_token)
        })
      } else {
        cb('no authentication token found')
      }
    }, 2000)
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    return JSON.parse(window.atob(base64))
  }

  parseHash(cb) {
    const params = this.readParams(window.location.hash)
    const { id_token, access_token } = params
    if (id_token && access_token) {
      cb(null, {
        idToken: id_token,
        accessToken: access_token,
        idTokenPayload: this.parseJwt(id_token)
      })
    } else {
      return cb('malformed jwt')
    }
  }
}

class Auth {
  accessToken
  idToken
  expiresAt

  authC = new authC(process.env.REACT_APP_AUTH_DOMAIN, {
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    redirect_uri: window.location.origin + '/callback',
    logout_uri: window.location.origin,
    // audience: process.env.REACT_APP_AUTH_AUDIENCE,
    response_type: 'token',
    scope: 'openid+profile+aws.cognito.signin.user.admin'
  })

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getIdToken = this.getIdToken.bind(this)
    this.silentAuth = this.silentAuth.bind(this)
  }

  login() {
    this.authC.authorize({ prompt: 'login' })
  }

  handleAuthentication() {
    console.log('handling Auth')
    return new Promise((resolve, reject) => {
      this.authC.parseHash((err, authResult) => {
        if (err) return reject(err)
        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        this.setSession(authResult)
        resolve(authResult)
      })
    })
  }

  setSession(authResult) {
    console.log('setting session', authResult)
    this.idToken = authResult.idToken
    this.accessToken = authResult.accessToken
    this.profile = authResult.idTokenPayload
    this.expiresAt = authResult.idTokenPayload.exp * 1000
    // Sentry.configureScope(scope => {
    //   scope.setUser({ email: authResult.email })
    // })
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.authC.checkSession({}, (err, authResult) => {
        if (err) {
          console.log('ERROR', err)
          return reject(err)
        }
        this.setSession(authResult)
        resolve()
      })
    })
  }

  async keepActive() {
    if (this.idToken && !this.isAuthenticated()) {
      await this.silentAuth()
    }
  }

  getAccessToken() {
    return this.accessToken
  }

  getIdToken() {
    return this.idToken
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null
    this.idToken = null
    this.profile = null
    this.expiresAt = 0
    this.authC.logout()
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt
    return new Date().getTime() < expiresAt
  }
}
const auth = new Auth()
export default auth
