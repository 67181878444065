import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withRouter } from 'react-router-dom'
import auth from '../helpers/Auth'
import AccountCircle from '@material-ui/icons/AccountCircle'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

class TopNavBar extends React.Component {
  static propTypes = {
    noTabs: PropTypes.bool,
    value: PropTypes.string,
    history: PropTypes.any,
    classes: PropTypes.any,
    match: PropTypes.any
  }
  state = {
    anchorEl: null
  }
  handleTabChange(event, value) {
    this.props.history.push(value)
  }
  logout() {
    auth.logout()
    // this.props.history.replace('/')
  }
  handleMenuClose() {
    this.setState({ anchorEl: null })
  }
  render() {
    const { classes, value } = this.props
    const { anchorEl } = this.state
    const isMenuOpen = Boolean(anchorEl)
    console.log(this.props.match.path)

    return (
      <div className={classes.root}>
        <AppBar color="default">
          <Toolbar>
            <Typography variant="h5" color="inherit" noWrap>
              Admin
            </Typography>
            {!this.props.noTabs && (
              <div className={classes.tabs}>
                <Tabs value={value} onChange={this.handleTabChange.bind(this)} className={classes.spaceLeft}>
                  {/* <Tab value="/home" label="Home" /> */}
                  <Tab value="/inventory" label="Inventory" />
                  <Tab value="/orders" label="Orders" />
                </Tabs>
                <div className={classes.grow} />
                <IconButton
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={event => this.setState({ anchorEl: event.currentTarget })}
                  color="inherit"
                >
                  <AccountCircle style={{ fontSize: '2rem' }} />
                </IconButton>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          style={{ top: '2.25rem' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={this.handleMenuClose.bind(this)}
        >
          <MenuItem onClick={this.logout.bind(this)}>Logout</MenuItem>
        </Menu>
      </div>
    )
  }
}

const styles = {
  tabs: {
    display: 'flex',
    flex: 1
  },
  root: {
    flexGrow: 1,
    zIndex: 9
  },
  spaceLeft: {
    paddingLeft: `1rem`
  },
  appBar: {
    zIndex: 9
  },
  grow: {
    flexGrow: 1
  }
}

export default withRouter(withStyles(styles)(TopNavBar))
