const topNavHeight = 64
const sidebarWidth = '15rem'
const defaultPadding = { padding: '1rem' }
const extraPadding = { padding: '2rem' }

const purple = '#32297a'

const drawerWidth = `15rem`
const drawerWidthRight = `720px`
export default theme => ({
  contentWithHeader: {
    marginTop: topNavHeight,
    ...defaultPadding
  },
  contentWithSidebar: {
    marginTop: topNavHeight,
    marginLeft: sidebarWidth,
    ...defaultPadding
  },
  extraPadding,
  button: {
    backgroundColor: purple
  },
  icon: {
    // color: purple,
    fontSize: '1.5rem'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 0
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1
  },
  drawerPaperRight: {
    width: drawerWidthRight,
    maxWidth: `calc(100vw - ${drawerWidth} - 1rem)`,
    height: 'calc(100vh - 4rem)',
    zIndex: 1,
    marginTop: topNavHeight,
    ...defaultPadding
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  toolbar: theme.mixins.toolbar,
  tableButton: {
    fontWeight: 500,
    paddingLeft: '1rem',
    color: purple,
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      textDecoration: 'underline !important',
      cursor: 'pointer',
      backgroundColor: 'transparent'
    }
  },
  tableCell: {
    height: '2rem',
    overflow: 'hidden'
  }
})
