import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import auth from 'helpers/Auth'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

class Callback extends React.Component {
  static propTypes = {
    history: PropTypes.any
  }
  async componentDidMount() {
    const authResult = await auth.handleAuthentication()
    console.log('CALLBACK')
    if (window.parent !== null) {
      window.parent.postMessage(authResult)
    }
    this.props.history.replace('/inventory')
  }
  render() {
    return <CircularProgress style={{ margin: '5rem auto' }} />
  }
}

export default withRouter(Callback)
