import React from 'react'
import {
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Chip,
  Container,
  Grid,
  Paper,
  Button
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import api from '../../api'
import TopNavBar from 'components/TopNavBar'

const toDollar = num => (num === null ? '...' : '$ ' + (Math.round(num * 100) / 100).toFixed(2))
const toChips = opts => (
  <div style={{ marginTop: '0.25rem' }}>
    {Object.entries(opts).map((kv, ix) => (
      <Chip
        key={ix}
        label={`${kv[0]} | ${kv[1]}`}
        size="small"
        style={{ margin: '0.125rem', textTransform: 'capitalize' }}
      />
    ))}
  </div>
)

class OrderItem extends React.Component {
  state = {
    loading: true,
    order: {}
  }
  async componentDidMount() {
    console.log(this.props)
    const response = await api.admin.orders.getOrder(this.props.match.params.id)
    console.log(response.data)
    this.setState({ order: response.data, loading: false })
  }
  render() {
    if (this.state.loading) {
      return (
        <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', paddingTop: '7rem' }}>
          <TopNavBar value="/orders" />
          <CircularProgress />
        </div>
      )
    }
    const { order } = this.state
    return (
      <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', paddingTop: '7rem' }}>
        <TopNavBar value="/orders" />
        <Typography variant="h5">Order Number: {order.order_number}</Typography>

        <Container maxWidth="sm" style={{ textAlign: 'left', paddingTop: '1rem' }}>
          <Paper>
            <Grid container style={{ paddingTop: '1rem' }}>
              <Grid item xs={6} style={{ borderLeft: '1px solid #ccc', padding: '0 1rem' }}>
                <Typography variant="h5">Ship To</Typography>
                <Typography variant="body1">
                  {order.shipping_first_name} {order.shipping_last_name}
                </Typography>
                <Typography variant="body1">{order.shipping_address1}</Typography>
                <Typography variant="body1">{order.shipping_address2}</Typography>
                <Typography variant="body1">
                  {order.shipping_city}, {order.shipping_state} {order.shipping_zip}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ borderLeft: '1px solid #ccc', padding: '0 1rem' }}>
                <Typography variant="h5">Bill To</Typography>
                <Typography variant="body1">
                  {order.billing_first_name} {order.billing_last_name}
                </Typography>
                <Typography variant="body1">{order.billing_address1}</Typography>
                <Typography variant="body1">{order.billing_address2}</Typography>
                <Typography variant="body1">
                  {order.billing_city}, {order.billing_state} {order.billing_zip}
                </Typography>
              </Grid>
            </Grid>
            <Table style={{ maxWidth: '100%', marginTop: '1rem', paddingTop: '0rem', borderTop: '1px solid #ccc' }}>
              <TableHead>
                <TableRow>
                  <TableCell className="sans small-header">Item</TableCell>
                  <TableCell className="sans small-header" align="right">
                    Qty
                  </TableCell>
                  <TableCell className="sans small-header" align="right">
                    Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.items.map((cartItem, ix) => (
                  <TableRow key={ix}>
                    <TableCell>
                      <Typography variant="body1">{cartItem.title}</Typography>
                      {cartItem.options && toChips(cartItem.options)}
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1">{cartItem.qty}</Typography>
                    </TableCell>
                    <TableCell align="right">{toDollar(cartItem.price * cartItem.qty)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }} className="summary-row">
                    SubTotal
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }} className="summary-row"></TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }} className="summary-row">
                    {toDollar(order.subtotal_cost)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }} className="summary-row">
                    Tax
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }} className="summary-row"></TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }} className="summary-row">
                    {toDollar(order.tax_cost)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="summary-row">S &amp; H</TableCell>
                  <TableCell className="summary-row"></TableCell>
                  <TableCell align="right" className="summary-row">
                    {toDollar(order.shipping_cost)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }} className="summary-row">
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }} className="summary-row"></TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }} className="summary-row">
                    <strong>{toDollar(order.total_cost)}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>

          <div className="flex-wrapper" style={{ paddingTop: '2rem' }}>
            <div className="flex-0">
              <Button variant="contained" onClick={() => this.props.history.push('/orders')}>
                &lt; Back
              </Button>
            </div>
            <div className="flex-1"></div>
            <div className="flex-0">
              <Button color="primary" variant="contained" onClick={this.setToShipped}>
                Set To Shipped and Send Email
              </Button>
            </div>
          </div>
        </Container>
      </div>
    )
  }

  setToShipped = async () => {
    await api.admin.orders.shipOrder(this.state.order)
  }
}

export default withRouter(OrderItem)
