import React from 'react'
import TopNavBar from 'components/TopNavBar'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Link, withRouter } from 'react-router-dom'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import api from '../../api'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

class InventoryList extends React.Component {
  state = {
    loading: true,
    data: []
  }

  async componentDidMount() {
    const response = await api.admin.inventory.getAll()
    console.log(response.data)
    this.setState({ data: response.data, loading: false })
  }

  render() {
    const { loading, data } = this.state
    if (loading) {
      return (
        <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh', paddingTop: '7rem' }}>
          <TopNavBar value="/orders" />
          <CircularProgress />
        </div>
      )
    }
    return (
      <div>
        <TopNavBar value="/inventory" />
        <main style={{ backgroundColor: '#eee' }}>
          <Typography variant="h4">Inventory</Typography>
          <div className="container">
            <div className="right" style={{ marginTop: '-5rem', marginBottom: '2rem' }}>
              <Fab color="secondary" onClick={() => this.props.history.push('/inventory/new')}>
                <AddIcon />
              </Fab>
            </div>
            <ReactTable
              data={data}
              defaultPageSize={15}
              pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
              className="-striped -highlight bg-white"
              resizable
              columns={[
                {
                  Header: 'ID',
                  id: 'id',
                  className: 'left',
                  accessor: d => (
                    <Link to={`/inventory/${d.id}`} className={'tableButton'}>
                      {d.id}
                    </Link>
                  )
                },
                {
                  Header: 'Title',
                  id: 'title',
                  className: 'center',
                  accessor: d => d.title
                },
                {
                  Header: 'Line',
                  id: 'line',
                  className: 'center',
                  accessor: d => `${d.line}`
                },
                {
                  Header: 'Qty On Hand',
                  id: 'on_hand_qty',
                  className: 'center',
                  accessor: d => `${d.on_hand_qty}`
                },
                {
                  Header: 'Qty Reseved',
                  id: 'reserved_qty',
                  className: 'center',
                  accessor: d => `${d.reserved_qty}`
                }
              ]}
            />
          </div>
        </main>
      </div>
    )
  }
}

export default withRouter(InventoryList)
