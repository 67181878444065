import Inventory from './inventory'
import Orders from './orders'
import Login from './Login'
import Callback from './Callback'

export default {
  Inventory,
  Orders,
  Login,
  Callback
}
