import xhr from './xhrFactory'

const buildUpdate = changes => {
  let update = []
  for (var key in changes) {
    update.push(`#${key} = :${key}`)
  }
  const ret = {
    update: `set ${update.join(', ')}`,
    params: changes
  }
  console.log(ret)
  return ret
}

export default {
  admin: {
    orders: {
      async getActiveOrders() {
        return xhr().get(`/api-admin/orders/active`)
      },
      async getOrder(id) {
        return xhr().get(`/api-admin/order/${id}`)
      },
      async updateOrder(id, changes) {
        return xhr().put(`/api-admin/order/${id}`, buildUpdate(changes))
      },
      async viewCharge(chargeId) {
        return xhr().get(`/api-admin/orders/charges/${chargeId}`)
      },
      async shipOrder(order) {
        return xhr().post(`/api-admin/orders/shipOrder`, { order })
      }
    },
    inventory: {
      async getAll() {
        return xhr().get(`/api-admin/inv/items`)
      },
      async getItem(id) {
        return xhr().get(`/api-admin/inv/item/${id}`)
      },
      async saveItem(id, data) {
        if (id) {
          return xhr().put(`/api-admin/inv/item/${id}`, { data })
        } else {
          // save item
          return xhr().post(`/api-admin/inv/items`, data)
        }
      },
      async deleteItem(id) {
        return xhr().delete(`/api-admin/inv/item/${id}`, { id: id })
      }
    }
  },
  public: {
    orders: {
      async submit(data) {
        return xhr().post(`/api/orders/submit`, data)
      },
      async build(data) {
        return xhr().post(`/api/orders/build`, data)
      }
    },
    inventory: {
      async get(id) {
        return xhr().get(`/api/inv/item/${id}`)
      },
      async getByCategory(category) {
        return xhr().get(`/api/inv/items/fromCategory/${category}`)
      }
    }
  },
  user: {}
}
